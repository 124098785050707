import {
  createEffect,
  createMemo,
  createSignal,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { getStoreOrderDetails } from "~/data/products";
import { clientRepo } from "~/server/apis/client_repo";
import { getV3GiftingDataStorageKey } from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import {
  OrderDetails,
  ShareableCoupon,
  VoucherRedemptionType,
} from "~/server/types/order";
import { Header } from "~/types";
import { APIError } from "~/utils/fetch";
import LocalStorageUtil from "~/utils/local_storage";
import { ThreeDotLoader } from "~/widgets/button";
import { GiftRootV3 } from "./order_sucess_gift_v3";
import {
  BrandDetailResponse,
  VoucherDetailResponse,
} from "~/server/types/gift";
import GiftCardGenerationInProgressComponent from "./generating";
import GiftCardGenerationFailedComponent from "./failed";
import { BrandCollectionHeader } from "../brand-collection-landing/brand_collection_header";

export type BrandCollectionOrderProps = {
  orderId: string;
  giftingKey: string;
  brand: BrandDetailResponse;
  coinsCount: number;
  logoUrl?: string;
};

export function BrandCollectionOrderComponent(
  props: BrandCollectionOrderProps
) {
  const [order, setOrder] = createSignal<OrderDetails | undefined>();
  const [shareableCoupon, setShareableCoupon] = createSignal<
    ShareableCoupon | null | undefined
  >(undefined);
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);
  const [showHeader, setShowHeader] = createSignal(false);

  let orderPollInterval: NodeJS.Timeout | undefined;

  async function setOrderStatus(orderId: string, sessionId: string) {
    try {
      setOrder(
        await getStoreOrderDetails(orderId, {
          [Header.SessionId]: sessionId,
        })
      );
    } catch (error) {
      if (error instanceof APIError) {
        if (error.code === 401) {
          window.location.href = "/gift-box/" + props.giftingKey;
        }
      }
    }
  }

  function getSessionId(): string {
    const sessionId = LocalStorageUtil.getItem<string>(
      getV3GiftingDataStorageKey(props.giftingKey, "sessionId")
    );
    return sessionId ?? "";
  }

  const setupOrderPolling = async (orderId: string) => {
    const sessionId = getSessionId();
    await setOrderStatus(orderId, sessionId!);

    orderPollInterval = setInterval(async () => {
      await setOrderStatus(orderId, sessionId!);
    }, 5000);
  };

  onMount(async () => {
    await setupOrderPolling(props.orderId);
  });

  createEffect(() => {
    if (!order()) return;

    if (
      order()?.status != "INITIALISED" &&
      order()?.status != null &&
      order()?.status != "PROCESSING"
    ) {
      clearInterval(orderPollInterval);
      console.log("voucher generated");
    }
  });

  async function fetchLatestBalance() {
    const response = await clientRepo.getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  const getCoins = createMemo<number>(() => {
    return latestCoinsBalance() ?? 0;
  });

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  createEffect(() => {
    if (order()?.status === "COMPLETED") {
      fetchShareableCoupon();
    }
  });

  async function fetchShareableCoupon() {
    try {
      const coupon = await clientRepo.getShareableCoupon({
        [Header.SessionId]: getSessionId(),
      });
      setShareableCoupon(coupon);
    } catch (error) {
      setShareableCoupon(null);
    }
  }

  return (
    <>
      <Show fallback={<ThreeDotLoader color="#fff" />} when={order()}>
        <Switch>
          <Match
            when={
              order()?.status == "PROCESSING" ||
              order()?.status === "INITIALISED" ||
              shareableCoupon() === undefined
            }
          >
            <GiftCardGenerationInProgressComponent />
          </Match>
          <Match when={order()?.status == "COMPLETED"}>
            <div class="bg-black">
              <div class="fixed top-0 z-20 w-full">
                <BrandCollectionHeader
                  giftingKey={props.giftingKey}
                  coinsAvailable={() => props.coinsCount}
                  showBalance={() => true}
                  showProfile={() => true}
                  showHeader={showHeader}
                  logoUrl={props.logoUrl}
                />
              </div>
              <div class="relative top-[52px]">
                <GiftRootV3
                  isPreview={false}
                  showGuidelines={false}
                  brand={props.brand}
                  voucher={getVoucherDetails()}
                  amount={order()!.amount}
                  description={`${order()?.orderProductDetails.voucherProductTitle} gift card is yours`}
                  onClickUnwrapGift={() => {}}
                  onUnwrapAnimationComplete={() => {
                    setShowHeader(true);
                  }}
                  shareableCoupon={shareableCoupon()}
                />
              </div>
            </div>
          </Match>
          <Match when={true}>
            <GiftCardGenerationFailedComponent />
          </Match>
        </Switch>
      </Show>
    </>
  );

  function getVoucherDetails(): VoucherDetailResponse {
    const voucher = order()!.orderProductDetails.vouchers![0];
    return {
      amount: order()!.amount,
      cardNumber: voucher.cardNumber,
      expiryDate: voucher.expiryDate ?? "",
      pin: voucher.cardPin,
      redemptionType: voucher.voucherMetadata.voucherRedemptionType,
    };
  }
}
